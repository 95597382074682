import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { inject } from '@angular/core';
import { mainConfigs } from 'src/app/configs/main-configs';


export const notLoggedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  if (!authService.getToken()) {
    const router = inject(Router);
    authService.setReturnURL(state.url)
    console.log(state.url)
    router.navigateByUrl(mainConfigs.loginURL);
    return false;
  }
  return true;
};
